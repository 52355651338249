/* eslint-disable no-script-url */

import React from 'react';
import Title from "../Title";
import {withStyles} from "@material-ui/core";
import {mitouStyles} from "../../Helpers/Styles";

function Fulfillment({classes, tasks}) {
    return (
        <React.Fragment>
            <Title className={classes.titleContainer}>Fulfillment</Title>
            Dummy
        </React.Fragment>
    );
}

export default withStyles(mitouStyles)(Fulfillment);
