import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import UserService from '../Service/UserService'
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        UserService.logout();

        this.state = {
            username: '',
            password: '',
            submitted: false,
            errorText: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLoginSuccess = this.handleLoginSuccess.bind(this);
        this.handleLoginFailed = this.handleLoginFailed.bind(this);
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});
        const {username, password} = this.state;
        if (username && password) {
            UserService.login(username, password).then(this.handleLoginSuccess).catch(this.handleLoginFailed)
        } else {
            this.setState({errorText: 'Bitte geben Sie Benutzernamen und Passwort an!'});
        }
    }

    handleLoginSuccess() {
        this.props.history.push('/');
    }

    handleLoginFailed() {
        this.setState({errorText: 'Benutzernamen oder Passwort falsch!'});
    }

    render() {
        const { classes } = this.props;
        const { errorText } = this.state;

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Typography component="h1" variant="h3" color="primary" align="center" gutterBottom>
                        Log-In
                    </Typography>
                    <form className={classes.form} onSubmit={this.handleSubmit} noValidate>
                        {errorText &&
                        <Typography component="h1" variant="h5" color="error" gutterBottom>
                            {errorText}
                        </Typography>
                        }
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Benutzername"
                            name="username"
                            autoComplete="username"
                            onChange={this.handleChange}
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Passwort"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={this.handleChange}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Einloggen
                        </Button>
                    </form>
                </div>
            </Container>
        );
    }
}

export default withStyles(styles)(LoginPage);
