/* eslint-disable no-script-url */

import React from 'react';
import clsx from 'clsx';
import Title from '../Title';
import {dashboardService} from "../../Service/DashboardService";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import {withStyles} from "@material-ui/core";
import {mitouStyles} from "../../Helpers/Styles";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {TaskCard} from "./TaskCard"
import moment from "moment";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {chatService} from "../../Service/ChatService";
import {taskActions} from "../../Actions/task";
import {connect} from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import TaskDetailDialog from "./TaskDetailDialog";

const WAIT_FOR_APPROVAL = 'waitForApproval';

class TaskTimeline extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            waitForApprovalTasks: null,
            error: false,
            initial: true,
            month: moment().utc().startOf('month'),
        };

        this.taskTimelineRef = React.createRef();
        this.dataTimeout = null;
        this.waitForApprovalTimeout = null;
        this.isComponentMounted = false;
        this.months = [];

        let monthCounter,
            month = moment().utc().startOf('month').subtract(12, 'months');

        for(monthCounter = 0; monthCounter < 18; monthCounter++) {
            this.months.push(moment(month));
            month.add(1, 'month');
        }

        this.requestDataSuccess = this.requestDataSuccess.bind(this);
        this.requestDataTimeout = this.requestDataTimeout.bind(this);
        this.requestData = this.requestData.bind(this);
        this.requestWaitForApprovalTimeout = this.requestWaitForApprovalTimeout.bind(this);
        this.requestWaitForApprovalSuccess = this.requestWaitForApprovalSuccess.bind(this);
        this.requestWaitForApproval = this.requestWaitForApproval.bind(this);
        this.changeMonth = this.changeMonth.bind(this);
        this.openDetailDialog = this.openDetailDialog.bind(this);
        this.scrollLeft = this.scrollLeft.bind(this);
        this.scrollRight = this.scrollRight.bind(this);
        this.submitMessage = this.submitMessage.bind(this);

    }

    requestDataSuccess(data) {
        if (!this.isComponentMounted) {
            return;
        }

        this.setState({data: data});
    }

    requestDataTimeout() {
        const {month} = this.state;
        if (this.isComponentMounted) {
            this.dataTimeout = setTimeout(() => this.requestData(month), 30000);
        }
    }

    requestData(month) {
        dashboardService.tasks(month)
            .then(this.requestDataSuccess)
            .finally(this.requestDataTimeout);
    }

    requestWaitForApprovalTimeout() {
        const {month} = this.state;
        if (this.isComponentMounted) {
            this.waitForApprovalTimeout = setTimeout(() => this.requestWaitForApproval(month), 30000);
        }
    }

    requestWaitForApprovalSuccess(data) {
        if (!this.isComponentMounted) {
            return;
        }
        const {month} = this.state;
        this.setState({waitForApprovalTasks: data});

        if (month === WAIT_FOR_APPROVAL) {
            this.setState({data: data});
            if (data.length === 0) {
                this.changeMonth({}, moment().toISOString())
            }
        }
    }

    requestWaitForApproval() {
        dashboardService.waitForApprovalTasks()
            .then(this.requestWaitForApprovalSuccess)
            .finally(this.requestWaitForApprovalTimeout);
    }

    componentDidMount() {
        const {month} = this.state;
        this.isComponentMounted = true;
        this.requestWaitForApproval();
        this.requestData(month);
    }

    componentWillUnmount() {
        this.isComponentMounted = false;
        if (this.dataTimeout !== null) {
            clearTimeout(this.dataTimeout);
        }
        if (this.waitForApprovalTimeout !== null) {
            clearTimeout(this.waitForApprovalTimeout);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {initial, data} = this.state;

        if (data !== null && initial) {
            let scrollLeft = this.taskTimelineRef.current.scrollWidth - this.taskTimelineRef.current.offsetWidth;
            if (scrollLeft > 0) {
                this.taskTimelineRef.current.scrollLeft = scrollLeft;
            }
            this.setState({initial: false});
        }
    }

    changeMonth(event, month) {
        const {waitForApprovalTasks} = this.state;
        if (this.dataTimeout !== null) {
            clearTimeout(this.dataTimeout);
        }
        if (month === WAIT_FOR_APPROVAL) {
            this.setState({month: WAIT_FOR_APPROVAL, data: [...waitForApprovalTasks], initial: true});
        } else {
            this.setState({month: moment(month), data: null, initial: true});
            this.requestData(month);
        }
    }

    openDetailDialog(task) {
        const {dispatch} = this.props;

        dispatch(taskActions.changeSelectedTask(task));
    }

    scrollRight() {
        this.taskTimelineRef.current.scrollLeft += this.taskTimelineRef.current.clientWidth * 0.9;
    }

    scrollLeft() {
        this.taskTimelineRef.current.scrollLeft -= this.taskTimelineRef.current.clientWidth * 0.9;
    }

    submitMessage(messageData) {
        const {selectedTask} = this.props;

        messageData.append('task', selectedTask.id);

        return chatService.sendMessage(messageData);
    }

    render() {
        const self = this;
        const {classes, selectedTask, weekCompleted} = this.props;
        const {data, month, waitForApprovalTasks} = this.state;

        let currentDate = null;
        let taskCounter = 0;

        return (
            <React.Fragment>
                <div className={classes.taskMonthContainer}>
                    <Title className={classes.taskTimelineTitle}>Aufgabenübersicht</Title>
                    {data !== null &&
                        <>
                            {weekCompleted !== null && (
                                <div className={classes.taskProgressContainer}>
                                    KW {moment().format('W')}
                                    &nbsp;&nbsp;<LinearProgress className={classes.taskProgressBar} classes={{barColorPrimary: classes.taskProgressBarBar}} variant="determinate" value={weekCompleted} />
                                    &nbsp;{weekCompleted}% abgeschlossen
                                </div>
                            )}
                        <AppBar position="static" className={classes.taskMonthTabAppBar} color={"inherit"}>
                            <Tabs value={month === WAIT_FOR_APPROVAL ? month : month.toISOString()}
                                  onChange={this.changeMonth}
                                  indicatorColor="secondary"
                                  textColor="secondary">
                                {waitForApprovalTasks !== null && waitForApprovalTasks.length > 0 &&
                                <Tab classes={{root: classes.taskMonth, selected: classes.taskMonthSelected, disabled: classes.taskMonthDisabled}} className={classes.taskMonthTab} value={WAIT_FOR_APPROVAL} label={"zu bestätigen"} wrapped={true}/>
                                }
                                {this.months.map(loopMonth => (
                                    <Tab classes={{root: classes.taskMonth, selected: classes.taskMonthSelected, disabled: classes.taskMonthDisabled}} className={classes.taskMonthTab} key={loopMonth.toISOString()} value={loopMonth.toISOString()} label={loopMonth.format('MMM YYYY')} disabled={loopMonth.isAfter(moment(), 'month')} wrapped={true}/>
                                ))}
                            </Tabs>
                        </AppBar>
                        </>
                    }
                </div>
                <div className={classes.dashboardTaskTimelineContainer}>
                    <div className={classes.dashboardTaskTimelineControl} onClick={this.scrollLeft}>
                        <FontAwesomeIcon fixedWidth={true} icon={['far', 'angle-left']} size={"lg"}/>
                    </div>
                    <div ref={this.taskTimelineRef} className={classes.taskTimeline}>
                        {data === null &&
                        <Box className={classes.progressContainer}>
                            <CircularProgress className={classes.progress}/>
                        </Box>
                        }
                        {data !== null && data.map(function (task) {
                            let showDate = false;

                            task.completedAt = moment(task.completedAt);

                            if (currentDate === null || !currentDate.isSame(task.completedAt, 'day')) {
                                currentDate = moment(task.completedAt);
                                showDate = true;
                            }

                            return (
                                <div key={task.id} className={classes.taskTimelineDateContainer}>
                                    <div className={classes.taskTimelineEntry}>
                                        <TaskCard taskCounter={++taskCounter} task={task}
                                                  clickHandler={self.openDetailDialog}/>
                                    </div>
                                    <div className={classes.taskTimelinePoint}><FontAwesomeIcon
                                        icon={["far", "tasks"]}/></div>
                                    {showDate &&
                                    <div className={classes.taskTimelineDateLabel}>{currentDate.format('DD.MM.')}</div>}
                                </div>
                            );
                        }, this)}
                        <div className={classes.taskTimelineDateContainer}>
                            <div className={clsx(classes.taskTimelineEntry, classes.taskTimelineDummyEntry)}>
                            </div>
                        </div>
                    </div>
                    <div className={classes.dashboardTaskTimelineControl} onClick={this.scrollRight}>
                        <FontAwesomeIcon fixedWidth={true} icon={['far', 'angle-right']} size={"lg"}/>
                    </div>
                </div>
                {selectedTask && (
                    <TaskDetailDialog selectedTask={selectedTask} />
                )}
            </React.Fragment>
        );
    }
}

const connectedWithStylesTaskTimeline = connect(mapStateToProps)(withStyles(mitouStyles)(TaskTimeline));


function mapStateToProps(state) {
    return {
        selectedTask: state.task
    };
}

export {connectedWithStylesTaskTimeline as TaskTimeline};
