import {withStyles} from "@material-ui/core/styles";
import {mitouStyles} from "../../Helpers/Styles";
import {useQuery} from "@apollo/react-hooks";
import React from "react";
import {gql} from "apollo-boost";
import {Hidden} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TaskList from "../Dashboard/TaskList";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import TaskDetailDialog from "../Dashboard/TaskDetailDialog";
import {useSelector} from "react-redux";

const GET_ADVERTISING_TASKS = gql`
    query getLastAdvertisingTasks {
        getLastAdvertisingTasks {
            id
            completedAt
            completedBy {
                color
                image
                initials
                firstName
                lastName
            }
            assignedTo {
                color
                image
                initials
                firstName
                lastName
            }
            pending
            needsCustomerApproval
            portalDescription
            service {
                name
            }
        }
    }
`;

function returnEveryNthElement(array, start, nth) {
    const result = [];
    for (let i = start; i < array.length; i = i+nth) {
        result.push(array[i]);
    }

    return result;
}

function AdvertisingTasks({classes}) {
    const {data} = useQuery(GET_ADVERTISING_TASKS);
    const selectedTask = useSelector(state => state.task);

    if (!data) {
        return (
            <Box className={classes.progressContainer}>
                <CircularProgress className={classes.progress}/>
            </Box>
        );
    }

    return (
        <Grid container spacing={2}>
            <Hidden lgUp>
                <Grid item lg={12}>
                    <TaskList tasks={data.getLastAdvertisingTasks} />
                </Grid>
            </Hidden>
            <Hidden mdDown>
                    <Grid item lg={4}>
                        <TaskList tasks={returnEveryNthElement(data.getLastAdvertisingTasks, 0, 3)} />
                    </Grid>
                    <Grid item lg={4}>
                        <TaskList tasks={returnEveryNthElement(data.getLastAdvertisingTasks, 1, 3)} />
                    </Grid>
                    <Grid item lg={4}>
                        <TaskList tasks={returnEveryNthElement(data.getLastAdvertisingTasks, 2, 3)} />
                    </Grid>
            </Hidden>
            {selectedTask && (
                <TaskDetailDialog selectedTask={selectedTask} />
            )}
        </Grid>
    );
}

export default withStyles(mitouStyles)(AdvertisingTasks);
