import {Route, Router} from "react-router-dom";
import Logout from "../Page/Logout";
import Login from "../Page/Login";
import {PrivateRoute} from "./PrivateRoute";
import Dashboard from "../Page/Dashboard";
import Advertising from "../Page/Advertising";
import Product from "../Page/Product";
import OutOfStock from "../Page/OutOfStock";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import UserService from "../Service/UserService";
import {getDashboardData} from "../Service/DashboardService";
import {changeAccountMarketplace} from "../Actions/account";


function BaseApp() {
    const dispatch = useDispatch();
    const account = useSelector(state => state.account.accountId);
    const marketplace = useSelector(state => state.account.marketplace);
    const [initial, setInitial] = useState(false);

    useEffect(() => {
        if (!UserService.isLoggedIn()) {
            return;
        }
        if (initial === true) {
            setInitial(false);
            return;
        }
        getDashboardData(account, marketplace)
            .then((data) => {
                if (account === null && marketplace === null) {
                    setInitial(true);
                    dispatch(changeAccountMarketplace(data['selectedAccount'].id, data['selectedMarketplace'], data['accounts']));
                }
            });

    }, [account, marketplace]);

    return (
        <>
            <Route exact path="/logout" component={Logout}/>
            <Route exact path="/login" component={Login}/>
            <PrivateRoute exact path='/' component={Dashboard}/>
            <PrivateRoute exact path='/advertising' component={Advertising}/>
            <PrivateRoute exact path='/product-checker' component={Product}/>
            <PrivateRoute exact path='/out-of-stock' component={OutOfStock}/>
        </>
    )
}

export default BaseApp;
