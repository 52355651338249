import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import {mitouStyles} from "../Helpers/Styles";
import React from "react";
import {changeAccountMarketplace} from "../Actions/account";
import {getUserAccounts} from "../Service/UserService";
import NavBar from "../Component/NavBar";
import ProductChecker from "../Component/Product/ProductChecker";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import Container from "@material-ui/core/Container";

class ProductCheckerPage extends React.Component {

    constructor(props) {
        super(props);

        this.isComponentMounted = false;

        this.handleSuccess = this.handleSuccess.bind(this);
        this.requestData = this.requestData.bind(this);
    }

    handleSuccess(data) {
        if (!this.isComponentMounted) {
            return;
        }
        const {dispatch} = this.props;

        dispatch(changeAccountMarketplace(data['selectedAccount'], data['selectedMarketplace'], data['accounts']));
    }

    requestData() {
        const {accountId, marketplace} = this.props;

        if (accountId === null || marketplace === null) {
            getUserAccounts().then(this.handleSuccess);
        }
    }

    componentDidMount() {
        this.isComponentMounted = true;
        this.requestData();
    }

    componentWillUnmount() {
        this.isComponentMounted = false;
    }

    render() {
        const {classes, accountId, marketplace} = this.props;
        const fullHeightPaper = clsx(classes.dashboardPaper, classes.dashboardFullHeight);

        return (
            <div className={classes.flexContainer}>
                <NavBar/>
                <main className={classes.dashboardContent}>
                    <Container maxWidth={false} className={classes.dashboardContainer}>
                        <Paper className={fullHeightPaper}>
                            {(!accountId || !marketplace) &&
                            <Box className={classes.progressContainer}>
                                <CircularProgress className={classes.progress}/>
                            </Box>
                            }
                            {accountId && marketplace &&
                            <ProductChecker/>
                            }
                        </Paper>
                    </Container>
                </main>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        accountId: state.account.accountId,
        marketplace: state.account.marketplace,
    };
}

export default connect(mapStateToProps)(withStyles(mitouStyles)(ProductCheckerPage));
