import React from "react";
import PropTypes from "prop-types";
import Select from "./Select";

const fullfillments = [
    {
        value: 'all',
        label: 'Alle'
    }, {
        value: 'fba',
        label: 'FBA'
    }, {
        value: 'fbm',
        label: 'FBM'
    }
];

function Fullfillment(props) {
    const {value, onChange} = props;

    return (
        <Select
            id="fullfillment-select"
            label="Versand"
            value={value}
            values={fullfillments}
            onChange={onChange}
        />
    )
}

Fullfillment.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default Fullfillment;
