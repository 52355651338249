import React from 'react';
import {Router} from 'react-router-dom'
import {ThemeProvider} from '@material-ui/styles';
import {mitouTheme} from "./Theme/MitouTheme";
import history from './Helpers/History';
import {library} from '@fortawesome/fontawesome-svg-core'
import {Provider} from "react-redux";
import {ApolloProvider} from '@apollo/react-hooks';
import {store} from "./Helpers/Store";
import {
    faAngleLeft,
    faAngleRight,
    faArrowCircleDown,
    faArrowCircleUp,
    faBellOn,
    faCheckCircle,
    faCheckSquare,
    faChevronLeft,
    faChevronRight,
    faChevronSquareDown,
    faChevronSquareUp,
    faComments,
    faEnvelope,
    faExclamationCircle,
    faExclamationSquare,
    faMinusCircle,
    faPaperclip,
    faPaperPlane,
    faPencil,
    faPlus,
    faQuestionCircle,
    faSearch,
    faSearchPlus,
    faSignOut,
    faSquare,
    faTasks,
    faTimes,
    faTimesSquare,
    faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons'
import {graphClient} from "./Component/GraphClient";
import {isPushNotificationSupported, registerServiceWorker} from "./Helpers/PushNotifications";
import BaseApp from "./Component/BaseApp";

library.add(
    faAngleLeft,
    faAngleRight,
    faArrowCircleDown,
    faArrowCircleUp,
    faCheckCircle,
    faCheckSquare,
    faChevronSquareDown,
    faChevronSquareUp,
    faChevronLeft,
    faChevronRight,
    faExclamationCircle,
    faExclamationSquare,
    faPaperclip,
    faPaperPlane,
    faPencil,
    faPlus,
    faQuestionCircle,
    faSearch,
    faSearchPlus,
    faSignOut,
    faSquare,
    faTasks,
    faTrashAlt,
    faTimesSquare,
    faComments,
    faEnvelope,
    faTimes,
    faMinusCircle,
    faBellOn,
);

function App() {
    if (isPushNotificationSupported()) {
        registerServiceWorker()
            .then((response) => {console.log(response);})
            .catch((response) => {console.log(response);});
    }

    return (
        <ApolloProvider client={graphClient}>
            <Provider store={store}>
                <ThemeProvider theme={mitouTheme}>
                    <Router history={history}>
                        <BaseApp />
                    </Router>
                </ThemeProvider>
            </Provider>
        </ApolloProvider>
    );
}

export default App;
