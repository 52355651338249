import {withStyles} from "@material-ui/core/styles";
import {mitouStyles} from "../Helpers/Styles";
import NavBar from "../Component/NavBar";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import {useSelector} from "react-redux";
import AdvertisingTable from "../Component/Advertising/AdvertisingTable";
import Title from "../Component/Title";
import AdvertisingTasks from "../Component/Advertising/AdvertisingTasks";

function AdvertisingPage({classes}) {
    const account = useSelector(state => state.account.accountId);
    const marketplace = useSelector(state => state.account.marketplace);

    return (
        <div className={classes.flexContainer}>
            <NavBar />
            <main className={classes.dashboardContent}>
                <Container maxWidth={false} className={classes.dashboardContainer}>
                    <Grid container spacing={2}>
                        {account && marketplace && (
                            <Grid item lg={12}>
                                <Paper className={classes.dashboardPaper}>
                                    <Title>Aufgaben</Title>
                                    <AdvertisingTasks/>
                                </Paper>
                            </Grid>
                        )}
                        <Grid item lg={12}>
                            <Paper className={classes.dashboardPaper}>
                                {!account && !marketplace && (
                                    <Box className={classes.progressContainer}>
                                        <CircularProgress className={classes.progress}/>
                                    </Box>
                                )}
                                {account && marketplace && (
                                    <React.Fragment>
                                        <Title>Statistiken</Title>
                                        <AdvertisingTable account={account} marketplace={marketplace}/>
                                    </React.Fragment>
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </div>
    );
}

export default withStyles(mitouStyles)(AdvertisingPage);
