import {CHANGE_ACCOUNT, CHANGE_ACCOUNT_MARKETPLACE, CHANGE_MARKETPLACE} from "../Actions/account";

const defaultState = {
    accountId: null,
    marketplace: null,
    accounts: [],
};

export function account(state = defaultState, action) {
    switch (action.type) {
        case CHANGE_ACCOUNT:
            return {
                ...state,
                accountId: action.accountId
            };

        case CHANGE_MARKETPLACE:
            return {
                ...state,
                marketplace: action.marketplace
            };

        case CHANGE_ACCOUNT_MARKETPLACE:
            return {
                ...state,
                accountId: action.accountId,
                marketplace: action.marketplace,
                accounts: action.accounts,
            };

        default:
            return {...state};
    }
}
