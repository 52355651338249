import React, {Fragment, useState} from "react";
import {withStyles} from "@material-ui/core";
import {gql} from "apollo-boost";
import {useMutation, useQuery} from "@apollo/react-hooks";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import {mitouStyles} from "../../Helpers/Styles";
import TextField from "../Form/TextField";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import PropTypes from "prop-types";

const GET_PORTAL_SETTING = gql`
query getPortalSetting {
    getPortalSetting {
        leadTimeFba
        leadTimeFbm
    }
}
`;
const UPDATE_LEAD_TIMES = gql`
mutation updateLeadTimes(
    $leadTimeFba: Int!
    $leadTimeFbm: Int!
) {
    updateLeadTimes(
        leadTimeFba: $leadTimeFba
        leadTimeFbm: $leadTimeFbm
    )
}
`;

function OutOfStockDialog(props) {
    const {classes, open, onClose} = props;
    const [leadTimeFba, setLeadTimeFba] = useState('');
    const [leadTimeFbm, setLeadTimeFbm] = useState('');
    const {loading} = useQuery(GET_PORTAL_SETTING, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setLeadTimeFba(data.getPortalSetting.leadTimeFba ? data.getPortalSetting.leadTimeFba.toString() : '');
            setLeadTimeFbm(data.getPortalSetting.leadTimeFbm ? data.getPortalSetting.leadTimeFbm.toString() : '');
        }
    });
    const [updateLeadTimes] = useMutation(UPDATE_LEAD_TIMES);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                Lead-Time bearbeiten
                <IconButton className={classes.closeButton} onClick={onClose}>
                    <FontAwesomeIcon icon={['far', 'times']}/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {loading &&
                <Box className={classes.progressContainer}>
                    <CircularProgress className={classes.progress}/>
                </Box>
                }
                {!loading &&
                    <Fragment>
                        <div>
                            <TextField
                                id="leadTimeFba"
                                label="Lead-Time FBA (in Tagen)"
                                value={leadTimeFba}
                                onChange={setLeadTimeFba}
                            />
                        </div>
                        <div>
                            <TextField
                                id="leadTimeFbm"
                                label="Lead-Time FBM (in Tagen)"
                                value={leadTimeFbm}
                                onChange={setLeadTimeFbm}
                            />
                        </div>
                    </Fragment>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={async () => {
                    await updateLeadTimes({variables: {leadTimeFba: leadTimeFba, leadTimeFbm: leadTimeFbm}});
                    onClose();
                }} color="primary">
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    )
}

OutOfStockDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};


export default withStyles(mitouStyles)(OutOfStockDialog);
