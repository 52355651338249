import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {UserAvatar} from "../UserAvatar";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {ChatWindow} from "../Chat/ChatWindow";
import {chatService} from "../../Service/ChatService";
import DialogActions from "@material-ui/core/DialogActions";
import React from "react";
import Button from "@material-ui/core/Button";
import {taskActions} from "../../Actions/task";
import {useDispatch} from "react-redux";
import {dashboardService} from "../../Service/DashboardService";
import {withStyles} from "@material-ui/core/styles";
import {mitouStyles} from "../../Helpers/Styles";


function TaskDetailDialog({classes, selectedTask}) {
    const dispatch = useDispatch();
    const requestApproved = (decision) => {
        dashboardService.approvedTask(selectedTask, decision).then((task) => dispatch(taskActions.changeSelectedTask(task)));
    };

    return (
        <Dialog open={true} onClose={() => dispatch(taskActions.changeSelectedTask(null))} classes={{paper: classes.mediumDialog}}>
            <DialogTitle className={classes.flexContainer} disableTypography={true}>
                <UserAvatar user={selectedTask.completedBy ? selectedTask.completedBy : selectedTask.assignedTo} className={classes.taskDialogAvatar} />
                <div className={classes.taskDialogTitle}>{selectedTask.service ? selectedTask.service.name : "Aufgabe"}</div>
                <IconButton className={classes.closeButton} onClick={() => dispatch(taskActions.changeSelectedTask(null))}>
                    <FontAwesomeIcon icon={['far', 'times']} />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText color={"textPrimary"} dangerouslySetInnerHTML={{ __html: selectedTask.portalDescription ? selectedTask.portalDescription : selectedTask.description}} />
                <ChatWindow chatTitle={"Kommentare"} submitMessage={(messageData) => {
                    messageData.append('task', selectedTask.id);

                    return chatService.sendMessage(messageData);
                }} loadData={() => chatService.loadTaskData(selectedTask.id) } />
            </DialogContent>
            <DialogActions>
                { selectedTask.pending && selectedTask.needsCustomerApproval &&
                <React.Fragment>
                    <Button onClick={() => requestApproved('yes')} color="primary">
                        <FontAwesomeIcon icon={["far", "check-square"]} /> Bestätigen
                    </Button>
                    <Button onClick={() => requestApproved('no')} color="primary">
                        <FontAwesomeIcon icon={["far", "times-square"]} /> Ablehnen
                    </Button>
                </React.Fragment>
                }
                <Button onClick={() => dispatch(taskActions.changeSelectedTask(null))} color="primary" autoFocus>
                    Schliessen
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withStyles(mitouStyles)(TaskDetailDialog);
