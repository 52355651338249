export function formatMoney(value, currency) {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: currency }).format(value);
}
export function formatMoneyByMarketplace(value, marketplace) {
    return formatMoney(value, getCurrencyByMarketplace(marketplace));
}

export function getCurrencyByMarketplace(marketplace) {
    switch (marketplace) {
        case 'UK':
            return 'GBP';

        case 'CA':
            return 'CAD';

        case 'MX':
            return 'MXN';

        case 'US':
            return 'USD';

        case 'BR':
            return 'BRL';

        case 'IN':
            return 'INR';

        case 'JP':
            return 'JPY';

        case 'CN':
            return 'CNY';

        case 'DE':
        case 'FR':
        case 'ES':
        case 'IT':
        default:
            return 'EUR';
    }
}

export function convertFloatToTime(floatTime) {
    let hours = parseInt(floatTime);
    let minutes = Math.floor((floatTime - hours) * 60);

    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    return hours + ':' + minutes;
}


export function renderPercentage(percent) {
    if (percent !== 0) {
        percent /= 100;
    }
    return new Intl.NumberFormat('de-DE', {style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(percent);
}

export function renderMoney(amount, currencyCode) {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: currencyCode }).format(amount);
}

export function renderFloat(amount) {
    return new Intl.NumberFormat('de-DE').format(amount);
}
