import {createMuiTheme} from "@material-ui/core";

export const mitouTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#3a6168',
            dark: '#274245'
        },
        secondary: {
            main: '#cc3d11'
        },
        text: {
            primary: '#264245',
            secondary: '#d9d9d9'
        },
        error: {
            main: '#cc3d11',
        },
        background: {
            default: '#d9d9d9',
        },
    },
    typography: {
        fontSize: 12,
    },
    overrides: {
        MuiTableCell: {
            sizeSmall: {
                paddingLeft: 10,
                paddingRight: 18
            },
            head: {
                color: "#264245",
                fontWeight: "bold"
            }
        },
        MuiFormLabel: {
            root: {
                color: "#3a6168"
            }
        },
        MuiFormControl: {
            root: {
                marginLeft: 10,
                marginRight: 10
            }
        }
    }
});


export const mitouInvertedTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#fff'
        },
        text: {
            primary: '#fff',
            secondary: '#d9d9d9'
        }
    },
    typography: {
        fontSize: 12,
    },
    overrides: {
        MuiTableCell: {
            sizeSmall: {
                paddingLeft: 10,
                paddingRight: 18
            }
        }
    }
});
