import Axios from 'axios';
import {config} from '../Constants'
import history from '../Helpers/History';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const PortalClient = Axios.create();

PortalClient.defaults.baseURL = config.API_URL;

const refreshAuthLogic = failedRequest => {
    return PortalClient.post('/token/refresh', {"refresh_token": localStorage.getItem('refreshToken')})
        .then(tokenRefreshResponse => {
            localStorage.setItem('userToken', tokenRefreshResponse.data.token);
            failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.token;

            return Promise.resolve();
        });
};

createAuthRefreshInterceptor(PortalClient, refreshAuthLogic);

PortalClient.interceptors.request.use(function (config) {
    const userToken = localStorage.getItem('userToken');

    if (config.url !== '/login_check' && userToken) {
        config.headers.common['Authorization'] = 'Bearer ' + userToken;
    }

    return config;
});

PortalClient.interceptors.response.use(null, function (error) {
    if (config.url !== '/login_check' && error.response && error.response.status === 401) {
        history.push('/login');
    }

    return Promise.reject(error);
});

export default PortalClient;
