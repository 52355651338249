import React from "react";
import {withStyles} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

function makeStyles() {

    return {
        fbaLabel: {
            backgroundColor: '#00A636',
            color: '#FFF'
        }
    }
}

function FullfillmentLabel(props) {
    const {fba, classes} = props;

    if (fba) {
        return (
            <Chip classes={{root: classes.fbaLabel}} label="FBA" size="small" />
        );
    }
    return (
        <Chip label="FBM" size="small" />
    );
}


export default withStyles(makeStyles)(FullfillmentLabel);
