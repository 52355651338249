const prod = {
    API_URL: 'https://portal.mitou.de/api/',
    GRAPHQL_URL: 'https://portal.mitou.de/graphql/',
    IMAGE_HOST: 'https://portal.mitou.de/',
    DEFAULT_MAIL: 'feedback@mitou.de',
    DEFAULT_PHONE: '02203 / 56 98 250'
};

const dev = {
    // API_URL: 'https://portal.mitou.dev.molabs.de/api/',
    // GRAPHQL_URL: 'https://portal.mitou.dev.molabs.de/graphql/',
    // IMAGE_HOST: 'https://portal.mitou.dev.molabs.de/',
    API_URL: 'https://portal.mitou.de/api/',
    GRAPHQL_URL: 'https://portal.mitou.de/graphql/',
    IMAGE_HOST: 'https://portal.mitou.de/',
    DEFAULT_MAIL: 'feedback@mitou.de',
    DEFAULT_PHONE: '02203 / 56 98 250'
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
