/* eslint-disable no-script-url */

import React from 'react';
import Title from '../Title';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import {withStyles} from "@material-ui/core";
import {mitouStyles} from "../../Helpers/Styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MomentUtils from "@date-io/moment";
import {todoService} from "../../Service/TodoService";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import "moment/locale/de";

class ToDos extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dataLoaded: false,
            data: null,
            error: false,
            filter: 'notCompleted',
            open: false,
            openDelete: false,
            selectedTodo: {},
            submitted: false
        };

        this.filters = [
            {
                value: 'notCompleted',
                label: 'offen'
            }, {
                value: 'dueToday',
                label: 'heute fällig'
            }, {
                value: 'overdue',
                label: 'überfällig'
            }
        ];

        this.requestTimeout = null;
        this.isComponentMounted = false;

        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleFailed = this.handleFailed.bind(this);
        this.handleFinally = this.handleFinally.bind(this);
        this.handleFinally = this.handleFinally.bind(this);
        this.requestData = this.requestData.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.openCreateDialog = this.openCreateDialog.bind(this);
        this.openUpdateDialog = this.openUpdateDialog.bind(this);
        this.openDeleteDialog = this.openDeleteDialog.bind(this);
        this.submitDialog = this.submitDialog.bind(this);
        this.submitDeleteDialog = this.submitDeleteDialog.bind(this);
        this.handleDescriptionChanged = this.handleDescriptionChanged.bind(this);
        this.handleDueAtChanged = this.handleDueAtChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSuccess(data) {
        if (!this.isComponentMounted) {
            return;
        }
        this.setState({dataLoaded: true, data: data, error: false});
    }

    handleFailed() {
        if (!this.isComponentMounted) {
            return;
        }
        this.setState({dataLoaded: false, data: null, error: true});
    }

    handleFinally() {
        if (!this.isComponentMounted) {
            return;
        }
        this.requestTimeout = setTimeout(() => {
            const {filter} = this.state;
            this.requestData(filter);
        }, 30000);
    }

    handleDescriptionChanged(e) {
        const {value} = e.target;
        const {selectedTodo} = this.state;

        selectedTodo.description = value;

        this.setState({selectedTodo: selectedTodo});
    }

    handleDueAtChanged(dueAt) {
        const {selectedTodo} = this.state;

        selectedTodo.dueAt = dueAt;

        this.setState({selectedTodo: selectedTodo});
    }

    closeDialog() {
        const {filter} = this.state;
        this.requestData(filter);
        this.setState({
            dataLoaded: false,
            data: null,
            open: false,
            openDelete: false,
            selectedTodo: {}
        });
    }

    openCreateDialog() {
        const selectedTodo = {
            dueAt: moment().add(1, 'd')
        };
        if (this.requestTimeout !== null) {
            clearTimeout(this.requestTimeout);
        }
        this.setState({open: true, selectedTodo: selectedTodo});
    }

    openUpdateDialog(todo) {
        if (this.requestTimeout !== null) {
            clearTimeout(this.requestTimeout);
        }
        this.setState({open: true, selectedTodo: todo});
    }

    openDeleteDialog(todo) {
        if (this.requestTimeout !== null) {
            clearTimeout(this.requestTimeout);
        }
        this.setState({openDelete: true, selectedTodo: todo});
    }

    submitDialog() {
        const {selectedTodo} = this.state;

        if (!selectedTodo.description || !selectedTodo.dueAt) {
            this.setState({submitted: true});
            return;
        }

        if (selectedTodo.id) {
            todoService.updateTodo(selectedTodo.id, selectedTodo).then(this.handleSubmit);
        } else {
            todoService.createTodo(selectedTodo).then(this.handleSubmit);
        }
    }

    handleSubmit() {
        this.closeDialog();
    }

    submitDeleteDialog() {
        const {selectedTodo} = this.state;

        if (this.requestTimeout !== null) {
            clearTimeout(this.requestTimeout);
        }
        todoService.deleteTodo(selectedTodo.id).then(this.handleSubmit);
    }

    requestData(filter) {
        todoService.getTodos(filter).then(this.handleSuccess).catch(this.handleFailed).finally(this.handleFinally);
    }

    componentDidMount() {
        const {filter} = this.state;
        this.isComponentMounted = true;
        this.requestData(filter);
    }

    componentWillUnmount() {
        this.isComponentMounted = false;
        if (this.requestTimeout !== null) {
            clearTimeout(this.requestTimeout);
        }
    }

    changeFilter(filter) {
        this.setState({
            filter: filter,
            dataLoaded: false,
            data: null
        });

        if (this.requestTimeout !== null) {
            clearTimeout(this.requestTimeout);
        }
        this.requestData(filter);
    }

    render() {
        const {classes} = this.props;
        const {open, openDelete, data, dataLoaded, filter, selectedTodo, submitted} = this.state;

        return (
            <React.Fragment>
                <Title>To-Do Liste</Title>
                {!dataLoaded &&
                <Box className={classes.progressContainer}>
                    <CircularProgress className={classes.progress}/>
                </Box>
                }
                {dataLoaded &&
                <React.Fragment>
                    <div className={classes.pillContainer}>
                        <Button size={"small"} variant="contained" className={classes.buttonCreate} onClick={this.openCreateDialog}><FontAwesomeIcon fixedWidth={true} icon={["far", "plus"]} /> To-Do</Button>
                        {this.filters.map(filterEntry => (
                            <Chip key={filterEntry.value} label={filterEntry.label}
                                  className={filterEntry.value === filter ? classes.pillActive : classes.pill}
                                  onClick={() => this.changeFilter(filterEntry.value)}/>
                        ))}
                    </div>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Aufgabe</TableCell>
                                <TableCell>Deadline</TableCell>
                                <TableCell>Eingetragen am</TableCell>
                                <TableCell>#</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map(todo => (
                                <TableRow key={todo.id}>
                                    <TableCell align={"right"}>{todo.description}</TableCell>
                                    <TableCell align={"right"}>{moment(todo.dueAt).format('DD.MM.YYYY')}</TableCell>
                                    <TableCell align={"right"}>{moment(todo.createdAt).format('DD.MM.YYYY')}</TableCell>
                                    <TableCell align={"right"}>
                                        <ButtonGroup>
                                            <Button size={"small"} variant="contained" onClick={() => this.openUpdateDialog(todo)}><FontAwesomeIcon fixedWidth={true} icon={["far", "pencil"]} /></Button>
                                            <Button size={"small"} variant="contained" onClick={() => this.openDeleteDialog(todo)}><FontAwesomeIcon fixedWidth={true} icon={["far", "trash-alt"]} /></Button>
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {data.length === 0 &&
                            <TableRow>
                                <TableCell colSpan={5}>
                                    Keine Einträge vorhanden
                                </TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                    <Dialog open={open} onClose={this.closeDialog}>
                        <DialogTitle id="form-dialog-title">{selectedTodo.id ? 'Eintrag bearbeiten' : 'Eintrag anlegen'}</DialogTitle>
                        <DialogContent>
                            <form onSubmit={this.submitDialog} noValidate>
                                <TextField
                                    multiline={true}
                                    autoFocus
                                    margin="dense"
                                    id="description"
                                    label="Aufgabe"
                                    value={selectedTodo.description}
                                    type="text"
                                    onChange={this.handleDescriptionChanged}
                                    fullWidth
                                    error={submitted && !selectedTodo.description}
                                    helperText={submitted && !selectedTodo.description ? "Das ist ein Pflichtfeld!" : null}
                                />
                                <MuiPickersUtilsProvider utils={MomentUtils} locale={"de"}>
                                    <DatePicker
                                        margin="dense"
                                        value={selectedTodo.dueAt}
                                        label={"Deadline"}
                                        format={"DD.MM.YYYY"}
                                        onChange={this.handleDueAtChanged}
                                        error={submitted && !selectedTodo.dueAt}
                                        helperText={submitted && !selectedTodo.dueAt ? "Das ist ein Pflichtfeld!" : null}
                                    />
                                </MuiPickersUtilsProvider>
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeDialog} color="primary">
                                Abbrechen
                            </Button>
                            <Button onClick={this.submitDialog} color="primary">
                                Speichern
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openDelete} onClose={this.closeDialog}>
                        <DialogTitle>Eintrag wirklich löschen?</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Dieser Eintrag wird unwiderrufflich gelöscht.<br />
                                Möchtest du fortfahren?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeDialog} color="primary">
                                Abbrechen
                            </Button>
                            <Button onClick={this.submitDeleteDialog} color="primary" autoFocus>
                                Eintrag Löschen
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

const withStylesToDos = withStyles(mitouStyles)(ToDos);

export {withStylesToDos as ToDos};
