import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IconButton from "@material-ui/core/IconButton";
import {InputAdornment} from "@material-ui/core";


function Search(props) {
    const {value, onChange} = props;
    const [searchValue, setSearchValue] = useState(value);

    return (
        <TextField
            id="search-input"
            label="Suche"
            type="text"
            value={searchValue}
            required={false}
            onChange={(event) => setSearchValue(event.target.value)}
            margin="normal"
            InputProps={{
                endAdornment:
                    <InputAdornment position="end">
                        <IconButton size={"small"} onClick={() => onChange(searchValue)}>
                            <FontAwesomeIcon icon={["far", "search"]} />
                        </IconButton>
                    </InputAdornment>,
            }}
            InputLabelProps={{
                shrink: true
            }}
        />
    )
}

Search.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default Search;
