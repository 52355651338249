/* eslint-disable no-script-url */

import React from 'react';
import Title from '../Title';
import {withStyles} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {ThemeProvider} from '@material-ui/styles';
import {mitouInvertedTheme} from "../../Theme/MitouTheme";
import {mitouStyles} from "../../Helpers/Styles";
import {convertFloatToTime} from "../../Helpers/NumberFormatting";

class AccountStatus extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dataFilter: 'accountHealth'
        };

        this.filters = [
            {
                value: 'accountHealth',
                label: 'Account Health'
            }, {
                value: 'defects',
                label: 'Mängel'
            }, {
                value: 'returnOrder',
                label: 'Rücksendungen'
            }, {
                value: 'responseTime',
                label: 'Antwortzeit'
            }
        ];

        this.renderTable = this.renderTable.bind(this);
        this.renderTableAccountHealth = this.renderTableAccountHealth.bind(this);
        this.renderTableDefects = this.renderTableDefects.bind(this);
        this.renderTableReturnOrder = this.renderTableReturnOrder.bind(this);
        this.renderTableResponseTime = this.renderTableResponseTime.bind(this);
        this.renderStatus = this.renderStatus.bind(this);
    }

    changeDataFilter(dataFilter) {
        this.setState({dataFilter: dataFilter});
    }

    renderTable() {
        const {dataFilter} = this.state;
        let result = '';

        if (dataFilter === 'accountHealth') {
            result = this.renderTableAccountHealth()
        } else if (dataFilter === 'defects') {
            result = this.renderTableDefects()
        } else if (dataFilter === 'returnOrder') {
            result = this.renderTableReturnOrder()
        } else if (dataFilter === 'responseTime') {
            result = this.renderTableResponseTime()
        }

        return result;
    }

    renderTableAccountHealth() {
        const {data} = this.props;

        return (
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>Bestellmängel</TableCell>
                        <TableCell>{this.renderStatus(data.orderDefectRate)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Stornorate</TableCell>
                        <TableCell>{this.renderStatus(data.orderCancellationRate)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>pünktliche Lieferung</TableCell>
                        <TableCell>{this.renderStatus(data.lateShipmentRate)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Rücksendungen</TableCell>
                        <TableCell>{this.renderStatus(data.onTimeDelivery)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Antwortzeit</TableCell>
                        <TableCell>{this.renderStatus(data.contactResponseTime)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Richtlinienverletzungen</TableCell>
                        <TableCell>{this.renderStatus(data.listingPolicyStatus)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }

    renderTableDefects() {
        const {data} = this.props;

        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Ziel</TableCell>
                        <TableCell>60 Tage</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Rate an Bestellmängeln</TableCell>
                        <TableCell>&lt; 1%</TableCell>
                        <TableCell>{this.renderPercentage(data.orderCount60d, data.orderWithDefects60d, 1, '<')}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Rate an negativem Feedback</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>{this.renderPercentage(data.orderCount60d, data.negativeFeedbacks60d)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Rate an A-bis-Z-Ansprüchen</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>{this.renderPercentage(data.orderCount60d, data.azClaims60d)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Servicerückerstattungsrate</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>{this.renderPercentage(data.orderCount60d, data.chargebacks60d)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }

    renderTableReturnOrder() {
        const {classes, data} = this.props;

        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Ziel</TableCell>
                        <TableCell className={classes.noWrap}>7 Tage</TableCell>
                        <TableCell className={classes.noWrap}>30 Tage</TableCell>
                        <TableCell className={classes.noWrap}>60 Tage</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Rücksendungen</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>{data.returnOrderCount7d}</TableCell>
                        <TableCell>{data.returnOrderCount30d}</TableCell>
                        <TableCell>{data.returnOrderCount60d}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Unzufriedenheit mit Rücksendungen</TableCell>
                        <TableCell>&lt;10%</TableCell>
                        <TableCell>{this.renderPercentage(data.returnOrderCount7d, data.returnDissatisfaction7d, 10, '<')}</TableCell>
                        <TableCell>{this.renderPercentage(data.returnOrderCount30d, data.returnDissatisfaction30d, 10, '<')}</TableCell>
                        <TableCell>{this.renderPercentage(data.returnOrderCount60d, data.returnDissatisfaction60d, 10, '<')}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Negativ bewertete Rücksendungen</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>{this.renderPercentage(data.returnOrderCount7d, data.negativeReturnFeedback7d)}</TableCell>
                        <TableCell>{this.renderPercentage(data.returnOrderCount30d, data.negativeReturnFeedback30d)}</TableCell>
                        <TableCell>{this.renderPercentage(data.returnOrderCount60d, data.negativeReturnFeedback60d)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Verspätete Antworten</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>{this.renderPercentage(data.returnOrderCount7d, data.lateResponse7d)}</TableCell>
                        <TableCell>{this.renderPercentage(data.returnOrderCount30d, data.lateResponse30d)}</TableCell>
                        <TableCell>{this.renderPercentage(data.returnOrderCount60d, data.lateResponse60d)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Ungültige Ablehnungen</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>{this.renderPercentage(data.returnOrderCount7d, data.invalidRejection7d)}</TableCell>
                        <TableCell>{this.renderPercentage(data.returnOrderCount30d, data.invalidRejection30d)}</TableCell>
                        <TableCell>{this.renderPercentage(data.returnOrderCount60d, data.invalidRejection60d)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }

    renderTableResponseTime() {
        const {classes, data} = this.props;
        const feedbackCount7d = data.responseUnder24Hours7d + data.responseTimeGreaterThan24Hours7d + data.noResponseForContactsOlderThan24Hours7d;
        const feedbackCount30d = data.responseUnder24Hours30d + data.responseTimeGreaterThan24Hours30d + data.noResponseForContactsOlderThan24Hours30d;
        const feedbackCount90d = data.responseUnder24Hours90d + data.responseTimeGreaterThan24Hours90d + data.noResponseForContactsOlderThan24Hours90d;

        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell className={classes.noWrap}>Ziel</TableCell>
                        <TableCell className={classes.noWrap}>7 Tage</TableCell>
                        <TableCell className={classes.noWrap}>30 Tage</TableCell>
                        <TableCell className={classes.noWrap}>60 Tage</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>durchschnittliche Antwortzeit</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>{convertFloatToTime(data.averageResponseTimeInHours7d)}</TableCell>
                        <TableCell>{convertFloatToTime(data.averageResponseTimeInHours30d)}</TableCell>
                        <TableCell>{convertFloatToTime(data.averageResponseTimeInHours90d)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Bearbeitungszeit unter 24 Stunden</TableCell>
                        <TableCell>&gt;90%</TableCell>
                        <TableCell>{this.renderPercentage(feedbackCount7d, data.responseUnder24Hours7d, 90, '>')}</TableCell>
                        <TableCell>{this.renderPercentage(feedbackCount30d, data.responseUnder24Hours30d, 90, '>')}</TableCell>
                        <TableCell>{this.renderPercentage(feedbackCount90d, data.responseUnder24Hours90d, 90, '>')}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Bearbeitungszeit über 24 Stunden</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>{this.renderPercentage(feedbackCount7d, data.responseTimeGreaterThan24Hours7d)}</TableCell>
                        <TableCell>{this.renderPercentage(feedbackCount30d, data.responseTimeGreaterThan24Hours30d)}</TableCell>
                        <TableCell>{this.renderPercentage(feedbackCount90d, data.responseTimeGreaterThan24Hours90d)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Seit mehr als 24 Std unbeantwortet</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>{this.renderPercentage(feedbackCount7d, data.noResponseForContactsOlderThan24Hours7d)}</TableCell>
                        <TableCell>{this.renderPercentage(feedbackCount30d, data.noResponseForContactsOlderThan24Hours30d)}</TableCell>
                        <TableCell>{this.renderPercentage(feedbackCount90d, data.noResponseForContactsOlderThan24Hours90d)}</TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        );
    }

    renderStatus(status) {
        const {classes} = this.props;
        let labelClass;

        if (!status) {
            status = 'Unknown';
        }

        switch (status) {
            case 'Good':
                labelClass = classes.labelSuccess;
                break;

            case 'Bad':
                labelClass = classes.labelDanger;
                break;

            case 'Fair':
                labelClass = classes.labelWarning;
                break;

            default:
                labelClass = classes.labelPrimary;
                break;
        }

        return (
                {
                    'Good': <FontAwesomeIcon fixedWidth={true} icon={["far", "check-circle"]} className={labelClass}/>,
                    'Bad': <FontAwesomeIcon fixedWidth={true} icon={["far", "exclamation-circle"]} className={labelClass}/>,
                    'Fair': <FontAwesomeIcon fixedWidth={true} icon={["far", "check-circle"]} className={labelClass}/>,
                    'Unknown': <FontAwesomeIcon fixedWidth={true} icon={["far", "question-circle"]} className={labelClass}/>
                }[status]
        );
    }

    renderPercentage(total, count, limit, highLow) {
        const {classes} = this.props;
        let percent = total > 0 && count > 0 ? count / total : 0;
        const spanClass = limit && ((highLow === '<' && limit/100 < percent) || (highLow === '>' && limit/100 > percent && total > 0)) ? classes.labelDanger : null;

        percent = new Intl.NumberFormat('de-DE', {style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(percent);

        return (
            <span className={spanClass}>
                {percent} ({count})
            </span>
        );
    }

    render() {
        const {classes} = this.props;
        const {dataFilter} = this.state;

        return (
            <ThemeProvider theme={mitouInvertedTheme}>
                <Title>Account Status</Title>
                <React.Fragment>
                    <div className={classes.pillContainer}>
                        {this.filters.map(filter => (
                            <Chip key={filter.value} label={filter.label}
                                  className={filter.value === dataFilter ? classes.pillActive : classes.pill}
                                  onClick={() => this.changeDataFilter(filter.value)}/>
                        ))}
                    </div>
                    {this.renderTable()}
                </React.Fragment>
            </ThemeProvider>
        );
    }
}

const withStylesAccountStatus = withStyles(mitouStyles) (AccountStatus);

export {withStylesAccountStatus as AccountStatus};
