import React, {useState} from "react";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import {mitouStyles} from "../../Helpers/Styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {gql} from "apollo-boost";
import {useQuery} from "@apollo/react-hooks";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {truncateName} from "../../Helpers/ItemHelper";
import FullfillmentLabel from "../Listing/FullfillmentLabel";
import Tendency from "../Tendency";
import moment from "moment";
import Title from "../Title";
import Select from "../Form/Select";
import Fullfillment from "../Form/Fullfillment";
import Search from "../Form/Search";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OutOfStockDialog from "./OutOfStockDialog";

const types = [
    {
        value: 'CURRENT_MONTH',
        label: 'kein Bestand'
    }, {
        value: 'NEXT_MONTH',
        label: 'kritischer Bestand'
    }, {
        value: 'HIDDEN',
        label: 'Ignorierte Artikel'
    }, {
        value: 'PROCESSED',
        label: 'Bearbeitete Artikel'
    }
];

const GET_OUT_OF_STOCK_QUERY = gql`
query getOutOfStock(
        $accountId: Int!
        $marketplace: Marketplace!
        $type: OutOfStockType!
        $search: String
        $fullfillment: String
        $offset: Int!
    ) {
    getOutOfStock(
        accountId: $accountId
        marketplace: $marketplace
        type: $type
        search: $search
        fullfillment: $fullfillment
        offset: $offset
    ) {
        statistic {
            quantityCurrentMonth
            quantityLastMonth
            outOfStockInDaysWithLead
            listing {
                id
                name
                sku
                currentQuantity
                fba
                product {
                    asin
                }
            }
        }
        outOfStock {
            hidden
            processed
            hiddenAt
            processedAt
        }
    }
}
`;

function OutOfStock(props) {
    const {classes, accountId, marketplace} = props;
    const [type, setType] = useState('CURRENT_MONTH');
    const [fullfillment, setFullfillment] = useState('all');
    const [search, setSearch] = useState('');
    const [openSettingDialog, setOpenSettingDialog] = useState(false);
    const {data, refetch} = useQuery(GET_OUT_OF_STOCK_QUERY, {
        variables: {
            accountId: accountId,
            marketplace: marketplace,
            type: type,
            search: search === '' ? null : search,
            fullfillment: fullfillment,
            offset: 0
        },
    });

    return (
        <React.Fragment>
            <Title>Out Of Stock</Title>
            <div className={classes.pillContainer}>
                <Select
                    id="filter-select"
                    label="Filter"
                    value={type}
                    values={types}
                    onChange={setType}
                />
                <Fullfillment value={fullfillment} onChange={setFullfillment}/>
                <Search value={search} onChange={setSearch}/>
                <div style={{alignSelf: 'flexEnd'}}>
                    <Button onClick={() => setOpenSettingDialog(true)} startIcon={<FontAwesomeIcon icon={["far", "pencil"]} />}>
                        Lead-Time bearbeiten
                    </Button>
                </div>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Artikel</TableCell>
                        <TableCell>Versand</TableCell>
                        <TableCell>ASIN</TableCell>
                        <TableCell>SKU</TableCell>
                        <TableCell>Bestand</TableCell>
                        <TableCell>Verkäufe (30 Tage)</TableCell>
                        <TableCell>Bestand für x Tage</TableCell>
                        <TableCell>Ignoriert am</TableCell>
                        <TableCell>Bearbeitet am</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!data &&
                    <TableRow>
                        <TableCell colSpan={9}>
                            <Box className={classes.progressContainer}>
                                <CircularProgress className={classes.progress}/>
                            </Box>
                        </TableCell>
                    </TableRow>
                    }
                    {data && data.getOutOfStock.map(({statistic, outOfStock}) => (
                        <TableRow key={statistic.listing.id} hover>
                            <TableCell>{truncateName(statistic.listing.name, 50)}</TableCell>
                            <TableCell><FullfillmentLabel fba={statistic.listing.fba}/></TableCell>
                            <TableCell>{statistic.listing.product.asin}</TableCell>
                            <TableCell>{statistic.listing.sku}</TableCell>
                            <TableCell>{statistic.listing.currentQuantity}</TableCell>
                            <TableCell><Tendency current={statistic.quantityCurrentMonth} before={statistic.quantityLastMonth}/> {statistic.quantityCurrentMonth}</TableCell>
                            <TableCell>{statistic.outOfStockInDaysWithLead}</TableCell>
                            <TableCell>{outOfStock && outOfStock.hiddenAt ? moment(outOfStock.hiddenAt).format('DD.MM.YYYY HH:mm') : '-' }</TableCell>
                            <TableCell>{outOfStock && outOfStock.processedAt ? moment(outOfStock.processedAt).format('DD.MM.YYYY HH:mm') : '-' }</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <OutOfStockDialog open={openSettingDialog} onClose={() => {
                    refetch();
                    setOpenSettingDialog(false);
                }}/>
            </Table>
        </React.Fragment>
    );
}

function mapStateToProps(state) {
    return {...state.account};
}

export default connect(mapStateToProps)(withStyles(mitouStyles)(OutOfStock));
