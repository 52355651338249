import React from 'react';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles';
import {mitouStyles} from "../Helpers/Styles";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import NavBar from "../Component/NavBar";
import {useSelector} from "react-redux";
import Dashboard from "../Component/Dashboard/Dashboard";

function DashboardPage({classes}) {
    const account = useSelector(state => state.account.accountId);
    const marketplace = useSelector(state => state.account.marketplace);
    const fixedHeightPaper = clsx(classes.dashboardPaper, classes.dashboardFixedHeight);

    return (
        <div className={classes.flexContainer}>
            <NavBar />
            <main className={classes.dashboardContent}>
                {!account && !marketplace &&
                <Container maxWidth={false} className={classes.dashboardContainer}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} className={classes.dashboardRelativeGridItem}>
                            <Paper className={fixedHeightPaper}>
                                <Box className={classes.progressContainer}>
                                    <CircularProgress className={classes.progress}/>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
                }
                {account && marketplace &&
                    <Dashboard account={account} marketplace={marketplace} />
                }
            </main>
        </div>
    );
}

export default withStyles(mitouStyles)(DashboardPage);
