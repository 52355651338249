import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {AccountStatus, Advertising, TaskTimeline} from ".";
import Consulting from "./Consulting";
import Container from "@material-ui/core/Container";
import React from "react";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {gql} from "apollo-boost";
import {mitouStyles} from "../../Helpers/Styles";
import {useQuery} from "@apollo/react-hooks";
import Content from "./Content";
import Fulfillment from "./Fulfillment";
import Support from "./Support";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

const DASHBOARD_DATA = gql`
    query getDashboardData(
        $account: Int!,
        $marketplace: Marketplace!
    ) {
        getDashboardData(
            accountId: $account
            marketplace: $marketplace
        ) {
            weekCompleted
            consulting {
                tasks {
                    id
                    completedAt
                    completedBy {
                        color
                        image
                        initials
                        firstName
                        lastName
                    }
                    assignedTo {
                        color
                        image
                        initials
                        firstName
                        lastName
                    }
                    pending
                    needsCustomerApproval
                    portalDescription
                    service {
                        name
                    }
                }
            }
            content {
                tasks {
                    id
                    completedAt
                    completedBy {
                        color
                        image
                        initials
                        firstName
                        lastName
                    }
                    assignedTo {
                        color
                        image
                        initials
                        firstName
                        lastName
                    }
                    pending
                    needsCustomerApproval
                    portalDescription
                    service {
                        name
                    }
                }
            }
            advertising {
                statistics {
                    id
                    range
                    impressionsOnlyOwn
                    clicksOnlyOwn
                    costOnlyOwn
                    conversionsOnlyOwn
                    salesOnlyOwn
                    cpcOnlyOwn
                    acosOnlyOwn
                    profile {
                        marketplace
                        currencyCode
                    }
                }
            }
            accountHealth {
                orderDefectRate
                orderCancellationRate
                lateShipmentRate
                onTimeDelivery
                contactResponseTime
                listingPolicyStatus
                orderCount60d
                orderWithDefects60d
                negativeFeedbacks60d
                azClaims60d
                chargebacks60d
                returnOrderCount7d
                returnOrderCount30d
                returnOrderCount60d
                returnDissatisfaction7d
                returnDissatisfaction30d
                returnDissatisfaction60d
                negativeReturnFeedback7d
                negativeReturnFeedback30d
                negativeReturnFeedback60d
                lateResponse7d
                lateResponse30d
                lateResponse60d
                invalidRejection7d
                invalidRejection30d
                invalidRejection60d
                responseUnder24Hours7d
                responseUnder24Hours30d
                responseUnder24Hours90d
                responseTimeGreaterThan24Hours7d
                responseTimeGreaterThan24Hours30d
                responseTimeGreaterThan24Hours90d
                noResponseForContactsOlderThan24Hours7d
                noResponseForContactsOlderThan24Hours30d
                noResponseForContactsOlderThan24Hours90d
                averageResponseTimeInHours7d
                averageResponseTimeInHours30d
                averageResponseTimeInHours90d
            }
        }
    }
`;

function Dashboard({classes, account, marketplace}) {
    const {data: dashboardData } = useQuery(DASHBOARD_DATA, {
        variables: {account, marketplace}
    });

    const fixedHeightPaper = clsx(classes.dashboardPaper, classes.dashboardFixedHeight);
    const taskTimelinePaper = clsx(classes.dashboardPaper, classes.dashboardTaskTimelinePaper);
    const accountStatusPaper = clsx(fixedHeightPaper, classes.dashboardAccountStatusGradient);
    const advertisingPaper = clsx(fixedHeightPaper, classes.dashboardAdvertisingGradient);
    const consultingTasks = dashboardData ? dashboardData.getDashboardData.consulting.tasks : null;
    const contentTasks = dashboardData ? dashboardData.getDashboardData.content.tasks : null;
    const advertisingData = dashboardData ? dashboardData.getDashboardData.advertising : null;
    const accountHealthData = dashboardData ? dashboardData.getDashboardData.accountHealth : null;
    const weekCompleted = dashboardData ? dashboardData.getDashboardData.weekCompleted : null;

    return (
        <Container maxWidth={false} className={classes.dashboardContainer}>
            {!dashboardData && (
                <Paper className={fixedHeightPaper}>
                    <Box className={classes.progressContainer}>
                        <CircularProgress className={classes.progress}/>
                    </Box>
                </Paper>
            )}
            {dashboardData && (
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.dashboardRelativeGridItem}>
                        <Paper className={taskTimelinePaper}>
                            <TaskTimeline weekCompleted={weekCompleted} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Paper className={fixedHeightPaper}>
                            <Consulting tasks={consultingTasks} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Paper className={fixedHeightPaper}>
                            <Content tasks={contentTasks} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} lg={6}>
                        <Paper className={advertisingPaper}>
                            <Advertising data={advertisingData} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Paper className={accountStatusPaper}>
                            <AccountStatus data={accountHealthData} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Paper className={fixedHeightPaper}>
                            <Support />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Paper className={fixedHeightPaper}>
                            <Fulfillment />
                        </Paper>
                    </Grid>
                </Grid>
            )}
        </Container>
    );
}

export default withStyles(mitouStyles)(Dashboard);
