import React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";

function Select(props) {
    const {id, label, value, values, onChange} = props;

    return (
        <TextField
            id={id}
            select
            label={label}
            value={value}
            required={false}
            onChange={(event) => onChange(event.target.value)}
            margin="normal"
            InputLabelProps={{
                shrink: true
            }}
        >
            {values.map((entry) => (
                <MenuItem key={entry.value}
                          value={entry.value}>{entry.label}</MenuItem>
            ))}
        </TextField>
    )
}

Select.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    values: PropTypes.array,
    onChange: PropTypes.func,
};

export default Select;
