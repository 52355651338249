import {
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    Typography,
    withStyles
} from "@material-ui/core";
import {mitouStyles} from "../../Helpers/Styles";
import {UserAvatar} from "../UserAvatar";
import React from "react";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import {taskActions} from "../../Actions/task";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch} from "react-redux";


function TaskListItem({classes, task}) {
    const dispatch = useDispatch();
    const element = document.createElement('div');
    element.innerHTML = task.portalDescription;

    const portalDescription = element.textContent;
    const user = task.completedBy ? task.completedBy : task.assignedTo;

    return (
        <ListItem>
            <ListItemAvatar>
                <UserAvatar user={user}/>
            </ListItemAvatar>
            <ListItemText
                primary={task.service ? task.service.name : 'Aufgabe'}
                secondary={
                    <React.Fragment>
                        <Tooltip title={moment(task.completedAt).subtract(10, 'minutes').format('DD.MM.YYYY HH:mm:ss')}>
                            <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary"
                            >{moment(task.completedAt).subtract(10, 'minutes').fromNow()}</Typography>
                        </Tooltip>
                        {' — ' + (portalDescription.length > 100 ? portalDescription.substr(0, 100) + '...' : portalDescription)}
                    </React.Fragment>
                }
            />
            <ListItemSecondaryAction>
                <IconButton size={"small"} className={classes.taskCardAction} onClick={() => dispatch(taskActions.changeSelectedTask(task))}>
                    <FontAwesomeIcon icon={["far", "search-plus"]} />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
}

export default withStyles(mitouStyles)(TaskListItem);
