import 'cross-fetch/polyfill';
import ApolloClient from 'apollo-boost';
import {config} from '../Constants'

export const graphClient = new ApolloClient({
    uri: config.GRAPHQL_URL,
    request: (operation) => {
        const token = localStorage.getItem('userToken');

        operation.setContext({
            headers: {
                authorization: token ? `Bearer ${token}` : "",
            }
        });
    }
});
