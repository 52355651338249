import {useSelector} from "react-redux";
import {withStyles} from "@material-ui/core";
import {mitouStyles} from "../Helpers/Styles";
import React from "react";
import NavBar from "../Component/NavBar";
import OutOfStock from "../Component/OutOfStock/OutOfStock";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import Container from "@material-ui/core/Container";

function OutOfStockPage({classes}) {
    const accountId = useSelector(state => state.account.accountId);
    const marketplace = useSelector(state => state.account.marketplace);
    const fullHeightPaper = clsx(classes.dashboardPaper, classes.dashboardFullHeight);

    return (
        <div className={classes.flexContainer}>
            <NavBar/>
            <main className={classes.dashboardContent}>
                <Container maxWidth={false} className={classes.dashboardContainer}>
                    <Paper className={fullHeightPaper}>
                        {(!accountId || !marketplace) &&
                        <Box className={classes.progressContainer}>
                            <CircularProgress className={classes.progress}/>
                        </Box>
                        }
                        {accountId && marketplace &&
                        <OutOfStock/>
                        }
                    </Paper>
                </Container>
            </main>
        </div>
    );
}

export default withStyles(mitouStyles)(OutOfStockPage);
