import React, {useState} from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AppBar from "@material-ui/core/AppBar";
import {withStyles} from "@material-ui/core";
import {mitouStyles} from "../Helpers/Styles";
import {connect, useDispatch} from "react-redux";
import {getItemById} from "../Helpers/ItemHelper";
import {changeAccount, changeMarketplace} from "../Actions/account";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from 'react-router-dom';
import Badge from "@material-ui/core/Badge";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {chatService} from "../Service/ChatService";
import {ChatWindow} from "./Chat/ChatWindow";
import {gql} from "apollo-boost";
import {useQuery} from "@apollo/react-hooks";
import {isPushNotificationSupported} from "../Helpers/PushNotifications";
import PushSubscriptionButton from "./NavBar/PushSubscriptionButton";

const COUNT_UNREAD_CHATS = gql`
{
    countUnreadChats
}
`;

function NavBar(props) {
    const {classes, accountId, marketplace, accounts} = props;
    const account = getItemById(accounts, accountId);
    const dispatch = useDispatch();
    const [chatOpen, setChatOpen] = useState(false);
    const {data} = useQuery(COUNT_UNREAD_CHATS, {pollInterval: 5000});
    const unreadChats = data ? data.countUnreadChats : null;

    return (
        <React.Fragment>
            <AppBar position="absolute">
                <Toolbar className={classes.flexContainer}>
                    <Typography className={classes.navEntries} component="h1" variant="h6" color="inherit" noWrap>
                        MiToU
                    </Typography>
                    <div className={classes.flexGrow}>
                        <Link className={classes.navEntries} color="inherit" to="/" component={RouterLink}>Dashboard</Link>
                        <Link className={classes.navEntries} color="inherit" to="/advertising" component={RouterLink}>Advertising</Link>
                        <Link className={classes.navEntries} color="inherit" to="/product-checker" component={RouterLink}>Produktchecker</Link>
                        <Link className={classes.navEntries} color="inherit" to="/out-of-stock" component={RouterLink}>Out Of Stock</Link>
                    </div>
                    {account &&
                    <React.Fragment>
                        <FormControl className={classes.navEntries} style={{minWidth: 200}}>
                            <InputLabel classes={{root: classes.navInputLabel}} htmlFor="account-select">Konto</InputLabel>
                            <Select
                                value={accountId}
                                onChange={(event) => dispatch(changeAccount(event.target.value))}
                                input={<Input classes={{root: classes.navInput}} name="account" id="account-select" />}
                                displayEmpty
                                name="account"
                            >
                                { accounts.map((accountData) => (
                                    <MenuItem key={accountData.id} value={accountData.id}>{accountData.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.navEntries} style={{width: 100}}>
                            <InputLabel classes={{root: classes.navInputLabel}} htmlFor="marketplace-select">Marktplatz</InputLabel>
                            <Select
                                value={marketplace}
                                onChange={(event) => dispatch(changeMarketplace(event.target.value))}
                                input={<Input classes={{root: classes.navInput}} name="marketplace" id="marketplace-select" />}
                                displayEmpty
                                name="marketplace"
                            >
                                { account.marketplaces.map((marketplaceEntry) => (
                                    <MenuItem key={marketplaceEntry} value={marketplaceEntry}>{marketplaceEntry}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </React.Fragment>
                    }
                    {isPushNotificationSupported() &&
                        <PushSubscriptionButton/>
                    }
                    <Badge classes={{badge: classes.iconButtonBadge}} max={10} color="secondary" badgeContent={unreadChats} anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}>
                        <IconButton onClick={() => setChatOpen(true)}>
                            <FontAwesomeIcon fixedWidth={true} icon={['far', 'envelope']}/>
                        </IconButton>
                    </Badge>
                    <IconButton href={"/logout"}>
                        <FontAwesomeIcon fixedWidth={true} icon={['far', 'sign-out']}/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Dialog open={chatOpen} onClose={() => setChatOpen(false)} classes={{paper: classes.mediumDialog}}>
                <DialogTitle>
                    Chat
                    <IconButton className={classes.closeButton} onClick={() => setChatOpen(false)}>
                        <FontAwesomeIcon icon={['far', 'times']} />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <ChatWindow showTask={true} chatTitle={"MiToU Chat"} submitMessage={chatService.sendMessage} loadData={chatService.loadData} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setChatOpen(false)} color="primary" autoFocus>
                        Schliessen
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

function mapStateToProps(state) {
    return {
        accountId: state.account.accountId,
        marketplace: state.account.marketplace,
        accounts: state.account.accounts,
    };
}

export default connect(mapStateToProps)(withStyles(mitouStyles)(NavBar));
