import {withStyles} from "@material-ui/core/styles";
import {mitouStyles} from "../../Helpers/Styles";
import {useQuery} from "@apollo/react-hooks";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import {truncateName} from "../../Helpers/ItemHelper";
import React, {useState} from "react";
import {gql} from "apollo-boost";
import {renderFloat, renderMoney, renderPercentage} from "../../Helpers/NumberFormatting";
import Select from "../Form/Select";

const GET_ADVERTISING_DATA = gql`
    query getAdvertisingCampaignData (
        $account: Int!
        $marketplace: Marketplace!
        $range: Int!
    ) {
        getAdvertisingCampaignData(
            accountId: $account
            marketplace: $marketplace
            range: $range
        ) {
            campaign {
                id
                name
                profile {
                    currencyCode
                }
            }
            reportByRange {
                impressions
                clicks
                cost
                conversions
                sales
                ctr
                cpc
                acos
                conversionRate
            }
        }
    }
`;
const RANGE_ALL =  '0';
const RANGE_CURRENT_WEEK =  '1';
const RANGE_LAST_WEEK =  '2';
const RANGE_CURRENT_MONTH =  '3';
const RANGE_LAST_MONTH =  '4';
const RANGE_CURRENT_YEAR =  '5';
const ranges = [
    {
        value: RANGE_ALL,
        label: 'Laufzeit'
    },
    {
        value: RANGE_CURRENT_WEEK,
        label: 'Aktuelle Woche'
    },
    {
        value: RANGE_LAST_WEEK,
        label: 'Letzte Woche'
    },
    {
        value: RANGE_CURRENT_MONTH,
        label: 'Aktueller Monat'
    },
    {
        value: RANGE_LAST_MONTH,
        label: 'Letzter Monat'
    },
    {
        value: RANGE_CURRENT_YEAR,
        label: 'Aktuelles Jahr'
    },
];

function AdvertisingTable({classes, account, marketplace}) {
    const [range, setRange] = useState(RANGE_ALL);
    const {data} = useQuery(GET_ADVERTISING_DATA, {
        variables: {account, marketplace, range: Number.parseInt(range)}
    });

    return (
        <>
            <div className={classes.pillContainer}>
                <Select
                    id="filter-select"
                    label="Zeitraum"
                    value={range}
                    values={ranges}
                    onChange={setRange}
                />
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Aufrufe</TableCell>
                        <TableCell>Klicks</TableCell>
                        <TableCell>Ausgaben</TableCell>
                        <TableCell>Bestellungen</TableCell>
                        <TableCell>Verkäufe</TableCell>
                        <TableCell>CTR</TableCell>
                        <TableCell>CPC</TableCell>
                        <TableCell>CVR</TableCell>
                        <TableCell>ACoS</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!data &&
                    <TableRow>
                        <TableCell colSpan={9}>
                            <Box className={classes.progressContainer}>
                                <CircularProgress className={classes.progress}/>
                            </Box>
                        </TableCell>
                    </TableRow>
                    }
                    {data && data.getAdvertisingCampaignData.map(({campaign, reportByRange}) => (
                        <TableRow key={campaign.id} hover>
                            <TableCell>{truncateName(campaign.name, 50)}</TableCell>
                            <TableCell align={"right"}>{reportByRange ? renderFloat(reportByRange.impressions) : '-'}</TableCell>
                            <TableCell align={"right"}>{reportByRange ? renderFloat(reportByRange.clicks) : '-'}</TableCell>
                            <TableCell align={"right"}>{reportByRange ? renderMoney(reportByRange.cost, campaign.profile.currencyCode) : '-'}</TableCell>
                            <TableCell align={"right"}>{reportByRange ? renderFloat(reportByRange.conversions) : '-'}</TableCell>
                            <TableCell align={"right"}>{reportByRange ? renderMoney(reportByRange.sales, campaign.profile.currencyCode) : '-'}</TableCell>
                            <TableCell align={"right"}>{reportByRange ? renderPercentage(reportByRange.ctr) : '-'}</TableCell>
                            <TableCell align={"right"}>{reportByRange ? renderMoney(reportByRange.cpc, campaign.profile.currencyCode) : '-'}</TableCell>
                            <TableCell align={"right"}>{reportByRange ? renderPercentage(reportByRange.conversionRate) : '-'}</TableCell>
                            <TableCell align={"right"}>{reportByRange ? renderPercentage(reportByRange.acos) : '-'}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}
export default withStyles(mitouStyles)(AdvertisingTable);
