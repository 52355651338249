import React, {createRef} from "react";
import {Divider, Box, withStyles, Input} from "@material-ui/core";
import {mitouStyles} from "../../Helpers/Styles";
import {MessageList} from "./MessageList"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IconButton from "@material-ui/core/IconButton";
import Title from "../Title";
import Tooltip from "@material-ui/core/Tooltip";

class ChatWindow extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            message: '',
            uploadFile: null
        };

        this.fileUploadRef = createRef();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fileChangeHandler = this.fileChangeHandler.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleSubmit(e) {
        const {submitMessage} = this.props;
        const {message, uploadFile} = this.state;

        e.target.reset();
        e.preventDefault();
        if (!message.trim()) {
            return;
        }
        const formData = new FormData();

        if (uploadFile !== null) {
            formData.append('file', uploadFile);
        }
        formData.append('message', message);

        submitMessage(formData);
        this.setState({message: '', uploadFile: null});
    }

    fileChangeHandler(e) {
        const uploadFile = e.target.files[0];

        this.setState({uploadFile: uploadFile});
    }

    deleteFile() {
        this.setState({uploadFile: null});
    }

    render() {
        const {onMessagesLoad, showTask, classes, loadData, chatTitle} = this.props;
        const {uploadFile} = this.state;

        return (
            <Box className={classes.chatWindow}>
                <Title>{chatTitle}</Title>
                <MessageList onLoad={onMessagesLoad} showTask={showTask} loadData={loadData} />
                <Divider />
                <form onSubmit={this.handleSubmit} className={classes.chatForm}>
                    <input type="file" ref={this.fileUploadRef} style={{ display: 'none' }} onChange={this.fileChangeHandler} />
                    <Input
                        multiline={true}
                        name="message"
                        placeholder="Nachricht schreiben"
                        className={classes.chatMessageInput}
                        onChange={this.handleChange}
                    />
                    <Tooltip title={"Datei anhängen"}>
                        <IconButton type={"button"} onClick={() => this.fileUploadRef.current.click() }>
                            <FontAwesomeIcon fixedWidth={true} icon={["far", "paperclip"]} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Nachricht absenden"}>
                        <IconButton type={"submit"}>
                            <FontAwesomeIcon fixedWidth={true} icon={["far", "paper-plane"]} />
                        </IconButton>
                    </Tooltip>
                </form>
                { uploadFile !== null &&
                    <div className={classes.chatMessageFileUploadContainer}>
                        <FontAwesomeIcon fixedWidth={true} icon={["far", "paperclip"]} /> {uploadFile.name}
                        <IconButton type={"button"} onClick={this.deleteFile} size={"small"}>
                            <FontAwesomeIcon fixedWidth={true} icon={["far", "trash-alt"]} />
                        </IconButton>
                    </div>
                }
            </Box>
        );
    }
}

const withStylesChatWindow = withStyles(mitouStyles)(ChatWindow);

export {withStylesChatWindow as ChatWindow};
