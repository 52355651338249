/* eslint-disable no-script-url */

import React from 'react';
import Title from "../Title";
import {withStyles} from "@material-ui/core";
import {mitouStyles} from "../../Helpers/Styles";
import TaskList from "./TaskList";

function Content({classes, tasks}) {
    return (
        <React.Fragment>
            <Title className={classes.titleContainer}>Content</Title>
            {tasks && (
                <TaskList tasks={tasks} />
            )}
        </React.Fragment>
    );
}

export default withStyles(mitouStyles)(Content);
