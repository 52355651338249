import {List, withStyles} from "@material-ui/core";
import React from "react";
import {mitouStyles} from "../../Helpers/Styles";
import TaskListItem from "./TaskListItem";

function TaskList({tasks}) {
    return (
        <List>
            {tasks.map((task, key) => (
                <TaskListItem key={key} task={task}/>
            ))}
        </List>
    )
}

export default withStyles(mitouStyles)(TaskList);
